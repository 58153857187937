$(window).on("load",function(){
  $('.top h2').addClass('fade_in');
});
$(function(){
	//SVGスプライト
	$.ajax({
		type: 'get',
		url: '/assets/svg/icons.svg'//ここにパスを入れる（環境に合わせて変更）
	}).done(function(data) {
		var svg = $(data).find('svg');
		$('body').prepend(svg);
	});
});

/*ページトップ指定*/
$(function(){
  var pagetop = $('#pagetop');
  // ボタン非表示
  pagetop.hide();

  // 500px スクロールしたらボタン表示
  $(window).scroll(function () {
     if ($(this).scrollTop() > 500) {
          pagetop.fadeIn();
     } else {
          pagetop.fadeOut();
     }
  });
  pagetop.click(function () {
     $('body, html').animate({ scrollTop: 0 }, 500);
     return false;
  });
});

/*ヘッダー処理*/
$(function(){
  // 15px 以上、スクロールしたらボタン表示
  $(window).scroll(function () {
     if ($(this).scrollTop() > 15) {
     	$('header').addClass('fixed');
     } else {
     	$('header').removeClass('fixed');
     }
  });
});

//ページ内スクロール
$(function(){
  $.smooziee();
});
/*ページ内リンクスムーススクロール*/
// $(function(){
//   $('a[href^="#"]').click(function(){
//     var speed = 500;
//     var href= $(this).attr("href");
//     var target = $(href == "#" || href == "" ? 'html' : href);
//     var ua = navigator.userAgent;
//     if (ua.indexOf('iPhone') > 0 && ua.indexOf('iPod') == -1 || ua.indexOf('Android') > 0 && ua.indexOf('Mobile') > 0 && ua.indexOf('SC-01C') == -1 && ua.indexOf('A1_07') == -1) {
//       var headerHight = 45;
//       var position = target.offset().top - headerHight;
//     } else {
//       var position = target.offset().top;
//     }
//     $("html, body").animate({scrollTop:position}, speed, "swing");
//     return false;
//   });
// });


/*スマホ時のドロワーメニュー*/
$(function() {
 $('.navbar_toggle').on('click', function () {
  // $(this).toggleClass('open');
  // $('.menu').toggleClass('open');
  $('header .sp-group .drawer').toggleClass('open');
  // $('body,html').css({"overflow":"hidden","height":"100%"});
 });
 $('.close').on('click', function () {
 	$('header .sp-group .drawer').removeClass('open');

 });

});

/*スマホ時のみ電話リンク差し込み*/
$(function(){
  var ua = navigator.userAgent;
  if (ua.indexOf('iPhone') > 0 && ua.indexOf('iPod') == -1 || ua.indexOf('Android') > 0 && ua.indexOf('Mobile') > 0 && ua.indexOf('SC-01C') == -1 && ua.indexOf('A1_07') == -1) {
    $('.tel-link').each(function () {
      var str = $(this).data("number");
      $(this).wrap('<a href="tel:' + str.replace(/-/g, '') + '" class="number-link"></a>');
    });
    /*ヘッダーの高さ分だけコンテンツを下げる*/
    //  var height=$("header").height();
    // $("body").css("margin-top", height + 20);//20pxだけ余裕をもたせる
  }
});

/*スライダー*/
$(function(){
   $( '#slider1' ).sliderPro({
    fade: true,
    fadeOutPreviousSlide: true,
    buttons: false,
    autoplayDelay: 6000,
    fadeDuration: 2000,
    touchSwipe: false,
    autoplayOnHover: 'none'
    // autoplay: false
   });
});

$(function(){
   $( '#slider2' ).sliderPro({
    fade: true,
    fadeOutPreviousSlide: true,
    buttons: false,
    autoplayDelay: 6000,
    fadeDuration: 2000,
    touchSwipe: false,
    autoplayOnHover: 'none'
   });
});

/*Datepicker*/
$(function() {
  $('.datepicker').datepicker({
    dateFormat: 'yy-mm-dd (DD)',
    yearSuffix: '年',
    showMonthAfterYear: true,
    monthNames: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
    dayNames: ['日', '月', '火', '水', '木', '金', '土'],
    dayNamesMin: ['日', '月', '火', '水', '木', '金', '土'],
    minDate: '+3d',
    maxDate: '+6m',
    hideIfNoPrevNext: true
  });
});

//アコーディオン01
$(function() {
  $('.ac_trigger').on('click', function () {
    if($(this).hasClass('open')){
      $(this).removeClass('open');
    } else {
      $(this).addClass('open');
    }
    // $(this).next(".ac_content").slideToggle();
    $(this).next(".ac_content").toggleClass('open');
  });
});

//アコーディオン02（スマホ）
$(function() {
  $('.sp_ac_trigger').on('click', function () {
    if($(this).hasClass('open')){
      $(this).removeClass('open');
    } else {
      $(this).addClass('open');
    }
    $(this).next(".sp_ac_content").slideToggle();
    // $(this).next(".sp_ac_content").toggleClass('open');
  });
});

//var headerHight = 50;
//var position = target.offset().top-headerHight;
